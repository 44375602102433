@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply bg-white text-slate-600;
  }

  .font-main {
    @apply tracking-wide font-normal;
    font-family: "Interstate-Compressed-Black", "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  .font-sec {
    @apply tracking-tight font-normal;
    font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .font-third {
    @apply tracking-tight font-normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .section {
  }
  p {
    @apply mb-2 last:mb-0;
  }
  .title-1 {
    @apply text-6xl mb-8 font-main;
  }

  .title-2 {
    @apply text-4xl sm:text-6xl mb-8 font-main;
  }
  .subtitle-2 {
    @apply text-base;
  }
  .section .inner {
    @apply w-full max-w-[1200px] mx-auto sm:px-8 p-8 sm:py-16;
  }

  .section-title-block {
    @apply font-main  p-2 inline-block text-[88px] leading-[88px];
  }

  .button {
    @apply bg-main-500  inline-block py-2 px-4 rounded-full text-dark-500 
    hover:scale-[1.05]
    hover:shadow-lg
    text-center
    transition-none transition-transform
    duration-300;
    font-family: "Interstate-Compressed-Black", "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }
  .button[disabled] {
    @apply bg-slate-200 text-slate-400 
    hover:scale-[1]
    hover:shadow-none
    text-center
    cursor-not-allowed
    transition-none transition-transform
    duration-300;
    font-family: "Interstate-Compressed-Black", "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  .button.button-inverse {
    @apply text-white
    hover:bg-main-500 hover:text-dark-500;
  }

  .button.big {
    @apply text-4xl py-4 px-8 tracking-wide;
  }
}
